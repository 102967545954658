// Ant components
import { Col, Row, Typography } from "antd";

// Custom styles
import ContainerStyles from "../../../styles/container.module.css";
import SectionStyles from "../../../styles/section.module.css";
import CardStyles from "../../../styles/card.module.css";
import ButtonStyles from "../../../styles/button.module.css";

// Images
import IconInfo from "images/icon-info.png";
import MalasyaImg from "images/malasya.png";
import BrooklynImg from "images/brooklyn.png";

// Components Typography
const { Title, Text } = Typography;

const styles = {
  title: {
    color: "#4f5969",
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: "1.5rem",
    marginBottom: "2rem",
  },
  text: {
    fontSize: "1rem",
  },
};

function Featured() {
  return (
    <section className={[SectionStyles.base].join(" ")}>
      <div className={ContainerStyles.base}>
        <Row
          gutter={[16, 16]}
          justify="center"
          align="middle"
          style={{ marginTop: "30px" }}
        >
          <Col>
            <Title level={2} style={styles.title}>
              Featured
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={{ span: 10 }}>
            <div className={CardStyles.base}>
              <Row
                gutter={[0, 8]}
                justify="start"
                align="middle"
                style={{ padding: "15px 0px 15px" }}
              >
                <Col lg={24}>
                  <span className={CardStyles.tagNew}>New</span>
                  <Text className={CardStyles.title}>Malasya Plots</Text>
                  <span className={CardStyles.tagDefault}>On Sale</span>
                </Col>
              </Row>

              <Row
                gutter={[8, 8]}
                justify="center"
                align="middle"
                style={{ marginTop: 16 }}
              >
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div
                    className={CardStyles.image}
                    style={{ backgroundImage: `url(${MalasyaImg})` }}
                  ></div>
                </Col>
              </Row>

              <Row
                gutter={[16, 16]}
                justify="start"
                align="middle"
                style={{ marginTop: 16 }}
              >
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className={CardStyles.box}>
                    <Text className={CardStyles.textNumber}>
                      31,25% <span className={CardStyles.tag}>APRF</span>
                    </Text>

                    <img
                      className={CardStyles.iconInfo}
                      title="Information"
                      src={IconInfo}
                    />
                  </div>
                  <span className={CardStyles.text}>9.345 Units available</span>
                </Col>

                <Col xs={24} sm={24} md={24} lg={{ span: 24 }}>
                  <a
                    href="#"
                    className={[
                      ButtonStyles.base,
                      ButtonStyles.primary,
                      ButtonStyles.slim,
                    ].join(" ")}
                  >
                    Buy now
                  </a>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={{ span: 10 }}>
            <div className={CardStyles.base}>
              <Row
                gutter={[0, 8]}
                justify="start"
                align="middle"
                style={{ padding: "15px 0px 15px" }}
              >
                <Col lg={24}>
                  <span className={CardStyles.tagNew}>New</span>
                  <Text className={CardStyles.title}>Brooklyn Properties</Text>
                  <span className={CardStyles.tagDefault}>On Sale</span>
                </Col>
              </Row>

              <Row
                gutter={[8, 8]}
                justify="center"
                align="middle"
                style={{ marginTop: 16 }}
              >
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div
                    className={CardStyles.image}
                    style={{ backgroundImage: `url(${BrooklynImg})` }}
                  ></div>
                </Col>
              </Row>

              <Row
                gutter={[16, 16]}
                justify="start"
                align="middle"
                style={{ marginTop: 16 }}
              >
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className={CardStyles.box}>
                    <Text className={CardStyles.textNumber}>
                      27,50% <span className={CardStyles.tag}>APRF</span>
                    </Text>

                    <img
                      className={CardStyles.iconInfo}
                      title="Information"
                      src={IconInfo}
                    />
                  </div>
                  <span className={CardStyles.text}>1.872 Units available</span>
                </Col>

                <Col xs={24} sm={24} md={24} lg={{ span: 24 }}>
                  <a
                    href="#"
                    className={[
                      ButtonStyles.base,
                      ButtonStyles.primary,
                      ButtonStyles.slim,
                    ].join(" ")}
                  >
                    Buy now
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* <Row >
          <Col >
            <div className={PortadaStyles.body}>
              <Title level={1} style={styles.title}>
                The self-sufficient XR world based on Web3
              </Title>
              <Text style={styles.text}>
                Crealand, through its digital layer deployed over the real
                world, allows the development of virtual new-land and
                activities, always profitable.
              </Text>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={{ span: 12 }}>
            <div className={PortadaStyles.image}></div>
          </Col>
        </Row> */}
      </div>
    </section>
  );
}

export default Featured;
