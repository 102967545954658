import { useLocation } from "react-router";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";

// Custom styles
import MenuStyles from "../styles/menu.module.css";

function MenuItems() {
  const { pathname } = useLocation();
  return (
    <Menu
      theme="light"
      mode="horizontal"
      style={{
        fontSize: "15px",
        fontWeight: "400",
        marginLeft: "20px",
        justifyContent: "flex-start",
        borderBottom: "0px",
      }}
      className={MenuStyles.base}
      defaultSelectedKeys={[pathname]}
    >
      <Menu.Item key="/login" className={MenuStyles.item}>
        <NavLink to="/login" className={MenuStyles.link}>
          Login
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/signup" className={MenuStyles.item}>
        <NavLink to="/signup" className={MenuStyles.link}>
          Signup
        </NavLink>
      </Menu.Item>
    </Menu>
  );
}

export default MenuItems;
