// Ant components
import { Col, Row, Typography } from "antd";

// Custom styles
import ContainerStyles from "../../../styles/container.module.css";
import SectionStyles from "../../../styles/section.module.css";
import ExchangeStyles from "../../../styles/exchange.module.css";
import ButtonStyles from "../../../styles/button.module.css";

// Images
import IconUSD from "images/icon-usd.png";
import IconCREA from "images/icon-crea.png";

// Components Typography
const { Title, Text } = Typography;

function Exchange() {
  return (
    <section className={[SectionStyles.base].join(" ")}>
      <div className={[ContainerStyles.base, ContainerStyles.pullup].join(" ")}>
        <Row
          gutter={[16, 16]}
          justify="center"
          align="middle"
          style={{ marginBottom: "10px" }}
        >
          <Col lg={20}>
            <Title className={ExchangeStyles.title} level={4}>
              Exchange
            </Title>
          </Col>
        </Row>
        <Row
          gutter={[64, 32]}
          justify="center"
          align="middle"
          style={{ marginBottom: "30px" }}
        >
          <Col xs={24} sm={24} md={12} lg={{ span: 10 }}>
            <div className={ExchangeStyles.card}>
              <div className={ExchangeStyles.cardHeader}>
                <img
                  className={ExchangeStyles.icon}
                  title="USD"
                  src={IconUSD}
                />
              </div>
              <div className={ExchangeStyles.cardBody}>
                <Text className={ExchangeStyles.textBig}>
                  1.00 CR-USD = 1.00 USD
                  <span className={ExchangeStyles.text}>
                    CREA Coin Balance: 0
                  </span>
                </Text>
              </div>
              <div>
                <a
                  href="#"
                  className={[
                    ButtonStyles.base,
                    ButtonStyles.primary,
                    ButtonStyles.slim,
                  ].join(" ")}
                >
                  Buy/Sell
                </a>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={{ span: 10 }}>
            <div className={ExchangeStyles.card}>
              <div className={ExchangeStyles.cardHeader}>
                <img
                  className={ExchangeStyles.icon}
                  title="USD"
                  src={IconCREA}
                />
              </div>
              <div className={ExchangeStyles.cardBody}>
                <Text className={ExchangeStyles.textBig}>
                  1.00 CR-USD = 1.00 USD
                  <span className={ExchangeStyles.text}>
                    CREA Coin Balance: 0
                  </span>
                </Text>
              </div>
              <div>
                <a
                  href="#"
                  className={[
                    ButtonStyles.base,
                    ButtonStyles.primary,
                    ButtonStyles.slim,
                  ].join(" ")}
                >
                  Buy/Sell
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          justify="center"
          align="middle"
          style={{ marginBottom: "10px" }}
        >
          <Col lg={20}>
            <Title className={ExchangeStyles.title2} level={4}>
              Staking
            </Title>
          </Col>
        </Row>
        <Row
          gutter={[64, 32]}
          justify="center"
          align="middle"
          style={{ marginBottom: "60px" }}
        >
          <Col xs={24} sm={24} md={12} lg={{ span: 10 }}>
            <div className={ExchangeStyles.card}>
              <div className={ExchangeStyles.cardHeader}>
                <img
                  className={ExchangeStyles.icon}
                  title="USD"
                  src={IconUSD}
                />
              </div>
              <div className={ExchangeStyles.cardBody}>
                <Text className={ExchangeStyles.textBig}>
                  <span className={ExchangeStyles.textMid}>APR</span> 5,00% |{" "}
                  <span className={ExchangeStyles.textMid}>Earn:</span> CR-USD
                </Text>

                <Row
                  justify="center"
                  align="middle"
                  style={{ marginTop: "15px" }}
                >
                  <Col xs={16} lg={16}>
                    <Text className={ExchangeStyles.textMid}>
                      CR-USD Earned: 11,22{" "}
                      <span className={ExchangeStyles.textGray}>
                        ~11,22 USD
                      </span>
                    </Text>
                  </Col>
                  <Col xs={8} lg={8} align="middle">
                    <a
                      href="#"
                      className={[
                        ButtonStyles.base,
                        ButtonStyles.secondary,
                      ].join(" ")}
                    >
                      Collect
                    </a>
                  </Col>
                </Row>

                <Row
                  justify="center"
                  align="middle"
                  style={{ marginTop: "0px" }}
                >
                  <Col xs={16} lg={16}>
                    <Text className={ExchangeStyles.textMid}>
                      CR-USD Staked: 220,00{" "}
                      <span className={ExchangeStyles.textGray}>
                        ~220,00 USD
                      </span>
                    </Text>
                  </Col>
                  <Col xs={8} lg={8} align="middle">
                    <a
                      href="#"
                      style={{ marginRight: "5px" }}
                      className={[
                        ButtonStyles.base,
                        ButtonStyles.secondary,
                        ButtonStyles.mini,
                      ].join(" ")}
                    >
                      +
                    </a>
                    <a
                      href="#"
                      className={[
                        ButtonStyles.base,
                        ButtonStyles.secondary,
                        ButtonStyles.mini,
                      ].join(" ")}
                    >
                      -
                    </a>
                  </Col>
                </Row>
              </div>
              <div>
                <a
                  href="#"
                  className={[
                    ButtonStyles.base,
                    ButtonStyles.primary,
                    ButtonStyles.slim,
                  ].join(" ")}
                >
                  Buy/Sell
                </a>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={{ span: 10 }}>
            <div className={ExchangeStyles.card}>
              <div className={ExchangeStyles.cardHeader}>
                <img
                  className={ExchangeStyles.icon}
                  title="USD"
                  src={IconCREA}
                />
              </div>
              <div className={ExchangeStyles.cardBody}>
                <Text className={ExchangeStyles.textBig}>
                  <span className={ExchangeStyles.textMid}>APR</span> 8,75% |{" "}
                  <span className={ExchangeStyles.textMid}>Earn:</span> CREA
                  COIN
                </Text>

                <Row
                  justify="center"
                  align="middle"
                  style={{ marginTop: "15px" }}
                >
                  <Col xs={16} lg={16}>
                    <Text className={ExchangeStyles.textMid}>
                      CREA Coin Earned: 0
                    </Text>
                  </Col>
                  <Col xs={8} lg={8} align="middle">
                    <a
                      href="#"
                      className={[
                        ButtonStyles.base,
                        ButtonStyles.secondary,
                      ].join(" ")}
                    >
                      Collect
                    </a>
                  </Col>
                </Row>

                <Row
                  justify="center"
                  align="middle"
                  style={{ marginTop: "0px" }}
                >
                  <Col xs={16} lg={16}>
                    <Text className={ExchangeStyles.textMid}>
                      CREA Coin Staked: 0
                    </Text>
                  </Col>
                  <Col xs={8} lg={8} align="middle">
                    <a
                      href="#"
                      style={{ marginRight: "5px" }}
                      className={[
                        ButtonStyles.base,
                        ButtonStyles.secondary,
                        ButtonStyles.mini,
                      ].join(" ")}
                    >
                      Stake
                    </a>
                  </Col>
                </Row>
              </div>
              <div>
                <a
                  href="#"
                  className={[
                    ButtonStyles.base,
                    ButtonStyles.primary,
                    ButtonStyles.slim,
                  ].join(" ")}
                >
                  Buy/Sell
                </a>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          gutter={[16, 16]}
          justify="center"
          align="middle"
          style={{ marginBottom: "0px" }}
        >
          <Col lg={20}>
            <hr />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Exchange;
