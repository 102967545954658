// Ant components
import { Col, Row, Typography } from "antd";

// Custom styles
import ContainerStyles from "../../../styles/container.module.css";
import SectionStyles from "../../../styles/section.module.css";
import ButtonStyles from "../../../styles/button.module.css";

// Images
import MapImg from "images/bg-map.png";

// Components Typography
const { Title, Text } = Typography;

const styles = {
  title: {
    color: "#4f5969",
    fontWeight: "bold",
    fontSize: "1.8rem",
    lineHeight: "2rem",
    marginBottom: "1.5rem",
  },
  text: {
    fontSize: "1rem",
  },
  textGray: {
    fontSize: "1.2rem",
    color: "#989898",
  },
  textNumber: {
    fontSize: "3.2rem",
    lineHeight: "3.2rem",
  },
  flexContent: {
    display: "flex",
    alignItems: "center",
  },
  tagGreen: {
    backgroundColor: "#84f5b1",
    color: "#989898",
    fontSize: "1rem",
    padding: "10px 25px",
    borderRadius: "5px",
    marginLeft: "20px",
  },
  tag: {
    fontSize: "1.2rem",
  },
  nav: {
    fontSize: "1.2rem",
    marginTop: "30px",
    display: "block",
  },
};

function Map() {
  return (
    <section
      className={[SectionStyles.base, SectionStyles.map].join(" ")}
      style={{ backgroundImage: `url(${MapImg})` }}
    >
      <div className={ContainerStyles.base}>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={{ span: 12, offset: 12 }}>
            <Title level={2} style={styles.title}>
              Territories
            </Title>
            <Row gutter={[8, 8]} justify="center" align="middle">
              <Col xs={24} sm={12} md={12} lg={{ span: 12 }}>
                <Text style={styles.textGray}>Available</Text>
                <div style={styles.flexContent}>
                  <Text style={styles.textNumber}>24</Text>
                  <span style={styles.tagGreen}>List</span>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={{ span: 12 }}>
                <Text style={styles.textGray}>Land Created</Text>
                <div>
                  <Text style={styles.textNumber}>16.549 </Text>
                  <span style={styles.tag}>Acres</span>
                </div>
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="center" align="middle">
              <Col xs={24} sm={24} md={24} lg={{ span: 24 }}>
                <Text style={styles.nav}>
                  Cubes: <strong>72.183</strong>{" "}
                  <span style={{ padding: "0px 5px" }}>|</span> Buildings:{" "}
                  <strong>116.928</strong>{" "}
                  <span style={{ padding: "0px 5px" }}>|</span> Properties:{" "}
                  <strong>635.298</strong>
                </Text>
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginTop: "40px" }}
            >
              <Col xs={24} sm={24} md={16} lg={{ span: 16 }}>
                <a
                  href="#"
                  className={[ButtonStyles.base, ButtonStyles.primary].join(
                    " ",
                  )}
                >
                  Create your own
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Map;
