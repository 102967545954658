// Ant components
import { Col, Row, Typography } from "antd";

// Custom styles
import ContainerStyles from "../../../styles/container.module.css";
import SectionStyles from "../../../styles/section.module.css";
import PortadaStyles from "../../../styles/portada.module.css";
import ButtonStyles from "../../../styles/button.module.css";

// Images
import PortadaImg from "images/portada.png";

// Components Typography
const { Title, Text } = Typography;

const styles = {
  title: {
    color: "#4f5969",
    fontWeight: "bold",
    fontSize: "3.1rem",
    lineHeight: "3.5rem",
    marginBottom: "2rem",
  },
  text: {
    fontSize: "1rem",
  },
};

function Introduction() {
  return (
    <section className={[SectionStyles.base, SectionStyles.intro].join(" ")}>
      <div className={ContainerStyles.base}>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={{ span: 12 }}>
            <div className={PortadaStyles.body}>
              <Title level={1} style={styles.title}>
                The self-sufficient XR world based on Web3
              </Title>
              <Text style={styles.text}>
                Crealand, through its digital layer deployed over the real
                world, allows the development of virtual new-land and
                activities, always profitable.
              </Text>

              <div className={PortadaStyles.button}>
                <a
                  href="#"
                  className={[ButtonStyles.base, ButtonStyles.primary].join(
                    " ",
                  )}
                >
                  Explore
                </a>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={{ span: 12 }}>
            <div className={PortadaStyles.image}>
              <img
                title="The self-sufficient XR world based on Web3"
                src={PortadaImg}
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Introduction;
