// Ant components
import { Col, Row, Typography } from "antd";

// Custom styles
import ContainerStyles from "../../../styles/container.module.css";
import SectionStyles from "../../../styles/section.module.css";
import DeFiStyles from "../../../styles/defi.module.css";
// import ButtonStyles from "../../../styles/button.module.css";

// Images
import DefiImg from "images/mobile-coins.png";

// Components Typography
const { Title, Text } = Typography;

function Defi() {
  return (
    <section className={[SectionStyles.base, SectionStyles.defi].join(" ")}>
      <div className={ContainerStyles.base}>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={{ span: 12 }}>
            <div className={DeFiStyles.imageBox}>
              <img
                title="Crealand DeFi"
                src={DefiImg}
                className={DeFiStyles.image}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={{ span: 12 }}>
            <div className={DeFiStyles.textBox}>
              <Title level={3} className={DeFiStyles.title}>
                Crealand Defi
              </Title>
              <Text className={[DeFiStyles.text].join(" ")}>
                <span className={DeFiStyles.textBig}>100% </span>of the capital
                contributed by the users is <strong>backed</strong> by the{" "}
                <br />
                stable-coin CR-USD.
              </Text>
              <Text className={[DeFiStyles.text].join(" ")}>
                Users Contribution: <strong>$10,827,109 USD</strong>
              </Text>
              <Text className={[DeFiStyles.text].join(" ")}>
                Guarantee pool: <br />
                <span className={DeFiStyles.textBig}>10,827,109 CR-USD</span>
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Defi;
