// React
import { useEffect } from "react";
import { useMoralis } from "react-moralis";
import { BrowserRouter as Router } from "react-router-dom";

// Ant components
import { Col, Row, Typography } from "antd";

// Components & pages
import Account from "components/Account/Account";
import Introduction from "components/Pages/Home/Introduction";
import Map from "components/Pages/Home/Map";
import Featured from "components/Pages/Home/Featured";
import DeFi from "components/Pages/Home/Defi";
import NFTs from "components/Pages/Home/NFTs";
import Exchange from "components/Pages/Home/Exchange";
import MenuItems from "./components/MenuItems";

// Styles
import { Layout } from "antd";
import "antd/dist/antd.css";
import "./style.css";

// Import custom styles
import HeaderStyles from "./styles/header.module.css";
import FooterStyles from "./styles/footer.module.css";
import ContainerStyles from "./styles/container.module.css";

// Components layout
const { Header, Footer } = Layout;
// Components Typography
const { Title, Text } = Typography;

// Images
import LogoImg from "images/logo.png";

const App = () => {
  const { isAuthenticated, isWeb3Enabled, enableWeb3, isWeb3EnableLoading } =
    useMoralis();

  useEffect(() => {
    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  // if (!isAuthenticated) return <QuickStart />;

  return (
    <Layout
      style={{ height: "100vh", overflow: "auto", backgroundColor: "#fff" }}
    >
      <Router>
        {/* Header */}
        <Header className={HeaderStyles.base}>
          <div className={HeaderStyles.col}>
            <Logo />
          </div>
          <div className={HeaderStyles.col}>
            <MenuItems />
            <Account />
          </div>
        </Header>
        {/* Content */}
        <div>
          <Introduction />
          <Map />
          <Featured />
          <DeFi />
          <Exchange />
          <NFTs />
        </div>
      </Router>
      <Footer className={FooterStyles.base}>
        <div className={ContainerStyles.base}>
          <Row
            gutter={[16, 16]}
            justify="center"
            align="middle"
            style={{ marginTop: "30px" }}
          >
            <Col>
              <Title level={2} className={FooterStyles.title}>
                About Crealand
              </Title>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            justify="center"
            align="middle"
            style={{ marginBottom: "60px" }}
          >
            <Col xs={24} sm={16} md={16} lg={{ span: 16 }}>
              <div style={{ textAlign: "center" }}>
                <Text className={FooterStyles.intro}>
                  Be part of the first self-sufficient XR world based on Web3
                </Text>
                <Text
                  className={[FooterStyles.text, FooterStyles.textCenter].join(
                    " ",
                  )}
                >
                  Crealand is a virtual world made up of NFT digital assets and
                  managed through smart contracts linked to a DAOs ecosystem.
                  Crealand is self-sufficient because it is financed through the
                  resources generated by its assets and deposits, not from the
                  funds of its users, which remains 100% liquid/available in the
                  platform.
                </Text>
                <br />

                <a href="#" className={[FooterStyles.button].join(" ")}>
                  Contact us
                </a>
              </div>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            justify="center"
            align="middle"
            style={{ marginTop: "30px" }}
          >
            <Col style={{ textAlign: "center" }}>
              <Title level={3} className={FooterStyles.title}>
                Become a Crealand Affiliate
              </Title>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            justify="center"
            align="middle"
            style={{ marginBottom: "40px" }}
          >
            <Col xs={24} sm={16} md={16} lg={{ span: 16 }}>
              <div style={{ textAlign: "center" }}>
                <Text
                  className={[FooterStyles.text, FooterStyles.textCenter].join(
                    " ",
                  )}
                >
                  You can get additional benefits from your involvement with
                  Crealand by becoming an active affiliate.
                </Text>
                <br />
                <a href="#" className={[FooterStyles.button].join(" ")}>
                  More info
                </a>
              </div>
            </Col>
          </Row>
          <Row gutter={[64, 32]} justify="center" align="middle">
            <Col xs={24} sm={24} md={12} lg={{ span: 20 }}>
              <hr style={{ borderBottomColor: "#fff" }} />
            </Col>
          </Row>
          <Row
            gutter={[64, 32]}
            justify="center"
            align="top"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            <Col xs={12} sm={12} md={6} lg={{ span: 4 }}>
              <Title level={5} className={[FooterStyles.title].join(" ")}>
                Project
              </Title>
              <ul className={[FooterStyles.list].join(" ")}>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Home
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Ecosystem
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Solutions
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    About Us
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Support
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Careers
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Whitepaper
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={6} lg={{ span: 4 }}>
              <Title level={5} className={[FooterStyles.title].join(" ")}>
                Create
              </Title>
              <ul className={[FooterStyles.list].join(" ")}>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Plots
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Cubes
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Buildings
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Units
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Business
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Services
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={6} lg={{ span: 4 }}>
              <Title level={5} className={[FooterStyles.title].join(" ")}>
                Trade
              </Title>
              <ul className={[FooterStyles.list].join(" ")}>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    CR-USD
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    CREA Coin
                  </a>
                </li>
                <li
                  className={[FooterStyles.listItem].join(" ")}
                  style={{ marginBottom: "20px" }}
                >
                  <a className={[FooterStyles.listLink].join(" ")}>Stake</a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Opensea
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={6} lg={{ span: 4 }}>
              <Title level={5} className={[FooterStyles.title].join(" ")}>
                Social
              </Title>
              <ul className={[FooterStyles.list].join(" ")}>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Twitter
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Instagram
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    YouTube
                  </a>
                </li>
                <li
                  className={[FooterStyles.listItem].join(" ")}
                  style={{ marginBottom: "20px" }}
                >
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Discord
                  </a>
                </li>
                <li
                  className={[FooterStyles.listItem].join(" ")}
                  style={{ marginBottom: "20px" }}
                >
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    <strong>Press</strong>
                  </a>
                </li>
                <li className={[FooterStyles.listItem].join(" ")}>
                  <a className={[FooterStyles.listLink].join(" ")} href="#">
                    Contact
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <Row gutter={[64, 32]} justify="center" align="middle">
            <Col xs={24} sm={24} md={12} lg={{ span: 20 }}>
              <hr style={{ borderBottomColor: "#fff" }} />
            </Col>
          </Row>
          <Row
            gutter={[64, 32]}
            justify="center"
            align="middle"
            style={{ marginTop: "30px" }}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={{ span: 10 }}
              justify="center"
              style={{ textAlign: "center" }}
            >
              <Text
                className={[FooterStyles.text, FooterStyles.textCenter].join(
                  " ",
                )}
              >
                Privacy Policy | Website Terms | Terms and Conditions | Referral
                Terms and Conditions
                <br />© 2022 Crealand Worlds
              </Text>
            </Col>
          </Row>
        </div>
      </Footer>
    </Layout>
  );
};

export const Logo = () => (
  <div style={{ display: "flex" }}>
    <img title="Beta" className={HeaderStyles.logo} src={LogoImg} />
  </div>
);

export default App;
