// Ant components
import { Col, Row, Typography } from "antd";

// Custom styles
import ContainerStyles from "../../../styles/container.module.css";
import SectionStyles from "../../../styles/section.module.css";
import ThemeStyles from "../../../styles/theme.module.css";

// Images
import IconPlots from "images/icon-plots.png";
import IconCubes from "images/icon-cubes.png";
import IconBuildings from "images/icon-buildings.png";
import IconUnits from "images/icon-units.png";
import IconBusiness from "images/icon-business.png";
import IconServices from "images/icon-services.png";
import IconForums from "images/icon-forums.png";
import IconGovernance from "images/icon-governance.png";

// Components Typography
const { Title, Text } = Typography;

function NFTs() {
  return (
    <section className={[SectionStyles.base].join(" ")}>
      <div className={ContainerStyles.base}>
        <Row gutter={[64, 32]} justify="center" align="middle">
          <Col xs={24} sm={24} md={12} lg={{ span: 10 }}>
            <Title level={2} className={ThemeStyles.title}>
              NFTs
            </Title>

            <div className={ThemeStyles.DescriptionBox}>
              <Text className={[ThemeStyles.text].join(" ")}>
                <span className={[ThemeStyles.textBig].join(" ")}>
                  <strong>100%</strong>{" "}
                  <span
                    className={[
                      ThemeStyles.textBold,
                      ThemeStyles.textRegular,
                    ].join(" ")}
                  >
                    {" "}
                    liquidity
                  </span>{" "}
                </span>{" "}
                Crealand NFTs! guaranteed by the <br /> CR-USD Pool.
              </Text>

              <Text
                className={[ThemeStyles.text, ThemeStyles.textBold].join(" ")}
              >
                Manage your Crealand digital assets thru NFTs
              </Text>

              <div style={{ marginTop: "30px" }}>
                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "10px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Plots" src={IconPlots} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Plots
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Become the owner of the digital layer plots
                      </Text>
                    </Col>
                  </Row>
                </div>

                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "10px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Cubes" src={IconCubes} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Cubes
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Create the territory and its virtual urban planning
                      </Text>
                    </Col>
                  </Row>
                </div>

                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "15px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Buildings" src={IconBuildings} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Buildings
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Build the parcels and generate value
                      </Text>
                    </Col>
                  </Row>
                </div>

                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "15px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Units" src={IconUnits} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Units
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Negotiate the rights over the territory
                      </Text>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={{ span: 10 }}>
            <Title level={2} className={ThemeStyles.title}>
              DAOs
            </Title>

            <div className={ThemeStyles.DescriptionBox}>
              <Text className={[ThemeStyles.text].join(" ")}>
                Develop always <strong>profitable activities!</strong> by
                joining the Crealand <br /> DAOs.
              </Text>

              <Text
                className={[ThemeStyles.text, ThemeStyles.textBold].join(" ")}
              >
                Manage your Crealand virtual activities thru DAOs
              </Text>

              <div style={{ marginTop: "30px" }}>
                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "10px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Business" src={IconBusiness} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Business
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Become the owner of the digital layer plots
                      </Text>
                    </Col>
                  </Row>
                </div>

                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "15px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Services" src={IconServices} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Services
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Create the territory and its virtual urban planning
                      </Text>
                    </Col>
                  </Row>
                </div>

                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "15px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Forums" src={IconForums} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Forums
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Build the parcels and generate value
                      </Text>
                    </Col>
                  </Row>
                </div>

                <div className={[ThemeStyles.listItem].join(" ")}>
                  <Row justify="start" align="middle" style={{ gap: "15px" }}>
                    <Col>
                      <div className={[ThemeStyles.iconBox].join(" ")}>
                        <img title="Governance" src={IconGovernance} />
                      </div>
                    </Col>
                    <Col>
                      <Title
                        className={[ThemeStyles.subtitle].join(" ")}
                        level={5}
                      >
                        Governance
                      </Title>
                      <Text className={[ThemeStyles.listItemText].join(" ")}>
                        Negotiate the rights over the territory
                      </Text>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default NFTs;
