import { Typography, Layout } from "antd";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
const { Text } = Typography;
// import Text from "antd/lib/typography/Text";
import Account from "components/Account/Account";
import TokenPrice from "components/TokenPrice";
import "antd/dist/antd.css";
const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "130px",
    padding: "10px",
    minHeight: "calc(100vh - (215px)",
    overflow: "auto",
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
  },
  base: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "130px",
    height: "70vh",
  },
  authContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "50px 25px",
    backgroundColor: "#fff",
    color: "#333",
    borderRadius: "8px",
    height: "400px",
  },
  text: {
    textAlign: "center",
  },
  btn: {
    backgroundColor: "#13c2c2",
    padding: "1rem 2rem",
    border: "0px",
    borderRadius: "4px",
    display: "block",
    cursor: "pointer",
    width: "100%",
  },
  footer: {
    textAlign: "center",
    background: "#333",
    padding: "2rem 1rem",
    color: "#eee",
  },
};
const { Header, Footer } = Layout;

export default function QuickStart() {
  return (
    <Layout style={{ height: "100vh", overflow: "auto" }}>
      <Router>
        <Header style={styles.header}>
          {/* <MenuItems /> */}
          <div style={styles.headerRight}>
            <TokenPrice
              address="0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6"
              chain="polygon"
              image="https://polygonscan.com/token/images/derace_32.png"
              size="32px"
            />
            <Account />
          </div>
        </Header>
        <div style={styles.content}></div>
      </Router>
      <Footer style={styles.footer}>
        <Text style={{ display: "block", color: "#eee" }}>
          Welcome to{"  "}
          <a
            href="https://smracing.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SMracing.io
          </a>
        </Text>
      </Footer>
    </Layout>
  );
}
